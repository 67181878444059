<template>
  <v-card style="width: 450px">
    <v-col align="center">

      <div v-if="currentState === 'passport'">
        <h3 class="mt-5 mb-2">Паспорт датчика Smarteco</h3>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">ID Объекта</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{ properties.id }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Название</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{ properties.name }}</v-card-text>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="4">
            <v-card-text class="text-start">Местоположение</v-card-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-card-text class="text-end">{{ properties.info }}</v-card-text>
          </v-col>
        </v-row>

        <v-row
          v-for="(item) in measurements"
          :key="item.id"
          dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">{{ item.measuredParameter.nameRU }}</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">{{ item.value }}</v-card-text>
          </v-col>
        </v-row>
      </div>

    </v-col>
  </v-card>
</template>

<script>

import Vue from "vue";

export default {
  name: "AirPassportSmarteco",

  props: { properties: Object, isDraw: Boolean },

  data() {
    return {
      currentState: 'passport',
      selectedIndicator: null,
      selectedPeriod: null,
      measurements: [],

      datacollection: {
        labels: ['1:00', '3:00', '5:00', '7:00', '9:00', '11:00', '13:00', '15:00', '17:00', '19:00', '21:00', '23:00'],
        datasets: [
          {
            label: 'мкг/м3',
            data: [20, 50, 100, 75, 50, 120, 80, 60, 130, 100, 90, 120],
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'мкг/м3'
            },
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Время'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    };
  },

  methods: {
    showStatistics() {
      this.currentState = 'statistics';
    },
    showPassport() {
      this.currentState = 'passport';
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    isVerificationExpired(verificationDate) {
      const oneYearLater = new Date(verificationDate);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      return new Date() >= oneYearLater;
    }
  },

  async mounted() {
    let targetUrl;
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const formattedDate = currentDate.toISOString().split('T')[0];
    targetUrl = `http://smarteco.kz:8084/api/MeasuredDatas?MonitoringPostId=${this.properties.id}&Averaged=true&DateTimeFrom=${formattedDate}&DateTimeTo=${formattedDate}`;
    const encodedUrl = encodeURIComponent(targetUrl);
    const response = await Vue.axios.get(`https://proxy.eco-monitor.kz/smarteco?url=${encodedUrl}`);

    this.measurements = response.data;
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>