import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: ["options"],
    mounted() {
        let chartOptions = {
            ...this.options,
            responsive: true,
            maintainAspectRatio: false
        };
        this.renderChart(this.chartData, chartOptions);
    },
    watch: {
        options: function() {
      this.$data._chart.destroy();
      this.renderChart(this.chartData, this.options);
        }
    },
};